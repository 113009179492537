
import { commonProps } from '../../utils/prismic';
import { pushHookV2Event } from '~/plugins/gtm';
import { LAYOUT_ACTION_FIXED_BUTTON } from '~/store/layout';

export default {
  props: commonProps,
  mounted() {
    this.setFixedButton(this.slice);
  },
  methods: {
    handleSubmit(formValue) {
      const url = new URL(this.slice.data.submitUrl);

      const queryParams = this.$route?.query;
      for (const key in queryParams) {
        url.searchParams.append(key, queryParams[key]);
      }

      for (const [key, value] of Object.entries(formValue)) {
        url.searchParams.append(key, value);
      }
      pushHookV2Event(
        this,
        this.slice.data.type,
        this.slice.data.form.ctaLabel
      );
      window
        .open(url.toString(), this.slice.data.cta.external ? '_blank' : '_self')
        .focus();
    },
    setFixedButton(slice) {
      const { hasFixedButton, cta } = slice.data;
      const ctaLabel = slice.data.form.ctaLabel;
      const storeValue = hasFixedButton
        ? {
            cta,
            label: ctaLabel,
            alwaysVisible: false,
          }
        : null;
      this.$store.dispatch(`layout/${LAYOUT_ACTION_FIXED_BUTTON}`, storeValue);
    },
  },
};
